import "./App.css";
import React from "react";
import LandingPage from "./Pages/landingPage";
import Dashboard from "./Pages/Dashboard";
import Quiz from "./Pages/Quiz";
import Profile from "./Pages/Profile";
import {
	BrowserRouter,
	Routes,
	Route
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Resources/Style/style.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import StartQuiz from "./Pages/StartQuiz";
import Completed from "./Pages/Completed";

function App() {
	return (
		<I18nextProvider i18n={i18n}>
			<div>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<LandingPage />} />
						<Route exact path="/dashboard" element={<Dashboard />} />
						<Route exact path="/results" element={<Completed />} />
						<Route
							exact
							path="/test/:id/:attempt/:time/:title/:resume"
							element={<Quiz />}
						/>
						<Route
							exact
							path="/test/:id/:attempt/:time/:title/start/:q?"
							element={<Quiz />}
						/>
						<Route path="/profile" element={<Profile />} />
						<Route exact path="/dashboard/start/:id" element={<StartQuiz />} />
						<Route
							path="/dashboard/start/:id/:attempt"
							element={<StartQuiz />}
						/>
					</Routes>
				</BrowserRouter>
			</div>
		</I18nextProvider>
	);
}

export default App;
