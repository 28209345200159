import React, { useState, useEffect } from "react";
import {
	Nav,
	Navbar,
	Image,
	Button,
	Form,
	Modal,
	Col,
	Row,
	NavDropdown,
	Alert,
	Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { api_url } from "./Variable";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import logo from "../Resources/Images/logo.png";

function NavigationBar(props) {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [lang, setLang] = useState("en");
	const [name, setName] = useState("");
	const [father_name, setFatherName] = useState("");
	const [phone, setPhone] = useState("");
	const [city, setCity] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [grade, setGrade] = useState("");
	const [institute, setInstitute] = useState("");
	const [email, setEmail] = useState("");
	const [results, setResult] = useState("");
	const [userId, setUserId] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [isScrolled, setIsScrolled] = useState(false);
	const [signUp, setSignUp] = useState(false);
	const [logIn, setLogin] = useState(false);
	const [hideBtn, setHideBtn] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const authenticate = localStorage.getItem("authenticate");
	const token = localStorage.getItem("token");
	const Name = localStorage.getItem("Name");
	const location = useLocation();
	const currentUrl = location.pathname;

	useEffect(() => {
		if (!token && !authenticate) {
			setHideBtn(true);
		}
	}, [token, authenticate]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setLang(lng);
		document.documentElement.dir = lng === "ur" ? "rtl" : "ltr";
		const font = lng === "ur" ? "Jameel Noori Nastaleeq" : "Roboto, sans-serif";
		document.body.style.fontFamily = font;
	};

	const handleSignUpClose = () => {
		setSignUp(false);
	};

	const handleSignUpShow = () => {
		setSignUp(true);
	};

	const handleLoginClose = () => {
		setLogin(false);
	};

	const handleLoginShow = () => {
		setLogin(true);
	};

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const scrollThreshold = 10;
		setIsScrolled(scrollPosition > scrollThreshold);
	};

	const SignUp = (e) => {
		setIsLoading(true);
		e.preventDefault();
		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);

		var formdata = new FormData();
		formdata.append("name", name);
		formdata.append("father_name", father_name);
		formdata.append("age", age);
		formdata.append("gender", gender);
		formdata.append("grade", grade);
		formdata.append("institute", institute);
		formdata.append("phone", phone);
		formdata.append("email", email);
		formdata.append("city", city);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "signup", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === true) {
					setName("");
					setFatherName("");
					setAge("");
					setGender("");
					setGrade("");
					setInstitute("");
					setPhone("");
					setEmail("");
					setCity("");
					setTimeout(() => {
						setLogin(true);
						setSignUp(false);
					}, 3000);
					setResult(result);
					setIsLoading(false);
				} else {
					setResult(result);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setIsLoading(false);
			});
	};

	const Login = (e) => {
		setIsLoading(true);
		e.preventDefault();

		var myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);

		var formdata = new FormData();
		formdata.append("userid", userId);
		formdata.append("password", userPassword);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "login", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				if (result.status === 1) {
					localStorage.setItem("token", result.token);
					localStorage.setItem("authenticate", true);
					localStorage.setItem("Name", result.data.name);
					localStorage.setItem("userName", result.data.user_name);
					localStorage.setItem("fatherName", result.data.father_name);
					localStorage.setItem("Age", result.data.age);
					localStorage.setItem("Gender", result.data.gender);
					localStorage.setItem("Grade", result.data.grade);
					localStorage.setItem("Institute", result.data.institute);
					localStorage.setItem("Phone", result.data.phone);
					localStorage.setItem("City", result.data.city);
					localStorage.setItem("Email", result.data.email);
					setIsLoading(false);
					navigate("/dashboard");
				} else {
					alert("Invalid credentials");
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.log("error", error);
				setIsLoading(false);
			});
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("authenticate");
		navigate("/");
	};

	return (
		<div>
			<Navbar expand="md" fixed="top">
				<Navbar.Brand href="/">
					<Image src={logo} />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto">
						<Nav.Link
							href="/"
							className={currentUrl === "/" ? "active-link" : ""}
						>
							{t("home")}
						</Nav.Link>
						{authenticate ? (
							<>
								<Nav.Link
									href="/dashboard"
									className={currentUrl === "/dashboard" ? "active-link" : ""}
								>
									{t("dashboard")}
								</Nav.Link>
								<Nav.Link
									href="/results"
									className={currentUrl === "/results" ? "active-link" : ""}
								>
									{t("results")}
								</Nav.Link>
								{lang === "en" ? (
									<Nav.Link onClick={() => changeLanguage("ur")} href="#link">
										{t("urdu")}
										<svg
											viewBox="0 0 24 24"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											aria-labelledby="languageIconTitle"
											stroke="white"
											stroke-width="1"
											stroke-linecap="square"
											stroke-linejoin="miter"
											fill="none"
											color="#000000"
											style={{ marginLeft: "0.4vw" }}
											className="langIcon"
										>
											<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
											<g
												id="SVGRepo_tracerCarrier"
												stroke-linecap="round"
												stroke-linejoin="round"
											></g>
											<g id="SVGRepo_iconCarrier">
												{" "}
												<title id="languageIconTitle">Language</title>{" "}
												<circle cx="12" cy="12" r="10"></circle>{" "}
												<path
													stroke-linecap="round"
													d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
												></path>{" "}
												<path
													stroke-linecap="round"
													d="M2.5 9L21.5 9M2.5 15L21.5 15"
												></path>{" "}
											</g>
										</svg>
									</Nav.Link>
								) : (
									<Nav.Link onClick={() => changeLanguage("en")} href="#link">
										{t("english")}
										<svg
											viewBox="0 0 24 24"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											aria-labelledby="languageIconTitle"
											stroke="white"
											stroke-width="1"
											stroke-linecap="square"
											stroke-linejoin="miter"
											fill="none"
											color="#000000"
											className="langIcon"
											style={{ marginRight: "0.4vw", marginTop: "0.22vw" }}
										>
											<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
											<g
												id="SVGRepo_tracerCarrier"
												stroke-linecap="round"
												stroke-linejoin="round"
											></g>
											<g id="SVGRepo_iconCarrier">
												{" "}
												<title id="languageIconTitle">Language</title>{" "}
												<circle cx="12" cy="12" r="10"></circle>{" "}
												<path
													stroke-linecap="round"
													d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
												></path>{" "}
												<path
													stroke-linecap="round"
													d="M2.5 9L21.5 9M2.5 15L21.5 15"
												></path>{" "}
											</g>
										</svg>
									</Nav.Link>
								)}
							</>
						) : (
							<>
								<Nav.Link onClick={handleSignUpShow}>{t("signUp")}</Nav.Link>
								{lang === "en" ? (
									<Nav.Link onClick={() => changeLanguage("ur")} href="#link">
										{t("urdu")}
										<svg
											viewBox="0 0 24 24"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											aria-labelledby="languageIconTitle"
											stroke="white"
											stroke-width="1"
											stroke-linecap="square"
											stroke-linejoin="miter"
											fill="none"
											color="#000000"
											className="langIcon"
											style={{ marginLeft: "0.4vw" }}
										>
											<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
											<g
												id="SVGRepo_tracerCarrier"
												stroke-linecap="round"
												stroke-linejoin="round"
											></g>
											<g id="SVGRepo_iconCarrier">
												{" "}
												<title id="languageIconTitle">Language</title>{" "}
												<circle cx="12" cy="12" r="10"></circle>{" "}
												<path
													stroke-linecap="round"
													d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
												></path>{" "}
												<path
													stroke-linecap="round"
													d="M2.5 9L21.5 9M2.5 15L21.5 15"
												></path>{" "}
											</g>
										</svg>
									</Nav.Link>
								) : (
									<Nav.Link onClick={() => changeLanguage("en")} href="#link">
										{t("english")}
										<svg
											viewBox="0 0 24 24"
											role="img"
											xmlns="http://www.w3.org/2000/svg"
											aria-labelledby="languageIconTitle"
											stroke="white"
											stroke-width="1"
											stroke-linecap="square"
											stroke-linejoin="miter"
											fill="none"
											color="#000000"
											className="langIcon"
											style={{ marginRight: "0.4vw", marginTop: "0.22vw" }}
										>
											<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
											<g
												id="SVGRepo_tracerCarrier"
												stroke-linecap="round"
												stroke-linejoin="round"
											></g>
											<g id="SVGRepo_iconCarrier">
												{" "}
												<title id="languageIconTitle">Language</title>{" "}
												<circle cx="12" cy="12" r="10"></circle>{" "}
												<path
													stroke-linecap="round"
													d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"
												></path>{" "}
												<path
													stroke-linecap="round"
													d="M2.5 9L21.5 9M2.5 15L21.5 15"
												></path>{" "}
											</g>
										</svg>
									</Nav.Link>
								)}
								<Nav.Link className="sign-up" onClick={handleLoginShow}>
									{t("logIn")}
								</Nav.Link>
							</>
						)}

						{authenticate && (
							<NavDropdown
								title={
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="currentColor"
										className="bi bi-person-circle"
										viewBox="0 0 16 16"
									>
										<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
										<path
											fill-rule="evenodd"
											d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
										/>
									</svg>
								}
								id="user-account"
							>
								<NavDropdown.Item className="user-name" disabled>
									{Name}
								</NavDropdown.Item>
								<NavDropdown.Item href="/profile">
									{t("profile")}
								</NavDropdown.Item>
								<NavDropdown.Item onClick={handleLogout}>
									{t("logout")}
								</NavDropdown.Item>
							</NavDropdown>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>

			<Modal show={signUp} onHide={handleSignUpClose} centered>
				<Modal.Title>{t("signUp")}</Modal.Title>
				<Modal.Body>
					<Form onSubmit={SignUp}>
						<Row>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="text"
										placeholder="&nbsp;"
										autoFocus
										required
										value={name}
										onChange={(e) => {
											setName(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("name")}</span>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="text"
										placeholder="&nbsp;"
										required
										value={father_name}
										onChange={(e) => {
											setFatherName(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("fname")}</span>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								<div className="form-group mb-3">
									<PhoneInput
										defaultCountry="PK"
										placeholder={t("phone")}
										required
										value={phone}
										onChange={setPhone}
									/>
								</div>
							</Col>
							<Col md={6}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="text"
										placeholder="&nbsp;"
										required
										value={city}
										onChange={(e) => {
											setCity(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("city")}</span>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col md={2}>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="number"
										placeholder="&nbsp;"
										required
										value={age}
										onChange={(e) => {
											setAge(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("age")}</span>
								</Form.Group>
							</Col>
							<Col md={5}>
								<Form.Group className="form-group mb-3">
									<Form.Select
										required
										value={gender}
										onChange={(e) => {
											setGender(e.target.value);
										}}
									>
										<option value="">{t("gender")}</option>
										<option value="Male">{t("male")}</option>
										<option value="Female">{t("female")}</option>
									</Form.Select>
								</Form.Group>
							</Col>
							<Col md={5}>
								<Form.Group className="form-group mb-3">
									<Form.Select
										required
										value={grade}
										onChange={(e) => {
											setGrade(e.target.value);
										}}
									>
										<option value="">{t("grade")}</option>
										<option value="A+">A+</option>
										<option value="A">A</option>
										<option value="B">B</option>
										<option value="C">C</option>
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group className="form-group mb-3">
									<Form.Control
										type="text"
										required
										placeholder="&nbsp;"
										value={institute}
										onChange={(e) => {
											setInstitute(e.target.value);
										}}
									/>{" "}
									<span className="floating-label">{t("institute")}</span>
								</Form.Group>
							</Col>
						</Row>
						<Form.Group className="form-group mb-3">
							<Form.Control
								type="email"
								required
								placeholder="&nbsp;"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>{" "}
							<span className="floating-label">{t("email")} </span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-envelope"
								viewBox="0 0 16 16"
							>
								<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
							</svg>
						</Form.Group>

						{results.error && (
							<Alert variant="danger">
								{typeof results.error === "object"
									? "Please fill all the fields"
									: results.error.toString()}
							</Alert>
						)}
						{results.message && (
							<Alert className="success">{results.message.toString()}</Alert>
						)}

						<Button type="submit" disabled={isLoading}>
							{isLoading ? (
								<Spinner
									animation="border"
									role="status"
									style={{ width: "1.8rem", height: "1.8rem" }}
								/>
							) : (
								t("create account")
							)}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>

			<Modal show={logIn} onHide={handleLoginClose} centered>
				<Modal.Title>{t("logIn")}</Modal.Title>
				<Modal.Body>
					<Form onSubmit={Login}>
						<Form.Group className="form-group mb-3">
							<Form.Control
								type="text"
								placeholder="&nbsp;"
								value={userId}
								onChange={(e) => {
									setUserId(e.target.value);
								}}
								autoFocus
								required
							/>
							<span className="floating-label">{t("userid")}</span>
						</Form.Group>
						<Form.Group className="form-group mb-3">
							<Form.Control
								type="password"
								placeholder="&nbsp;"
								required
								value={userPassword}
								onChange={(e) => {
									setUserPassword(e.target.value);
								}}
							/>{" "}
							<span className="floating-label">{t("password")}</span>
						</Form.Group>
						<Button type="submit" disabled={isLoading}>
							{isLoading ? (
								<Spinner
									animation="border"
									role="status"
									style={{ width: "1.8rem", height: "1.8rem" }}
								/>
							) : (
								t("logIn")
							)}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
}

export default NavigationBar;
