import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "../src/Resources/locales/en.json";
import translationUr from "../src/Resources/locales/ur.json";

const resources = {
	en: { translation: translationEn },
	ur: { translation: translationUr },
};

i18n.use(initReactI18next).init({
	resources,
	lng: "en", 
	fallbackLng: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
