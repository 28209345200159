import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {api_url} from "../Common/Variable";
import {Button, Modal} from "react-bootstrap";
import NavigationBar from "../Common/Navbar";
import Spinner from "react-bootstrap/Spinner";

function StartQuiz() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [lang, setLang] = useState(i18n.language);
    const token = localStorage.getItem("token");
    const authenticate = localStorage.getItem("authenticate");
    const [isLoading, setIsLoading] = useState(true);
    const [quizDetails, setQuizDetails] = useState("");
    const {id, attempt} = useParams();
    const parsedId = parseInt(id, 10);
    const [resumed, setResumed] = useState("resume");
    const [completed, setCompleted] = useState(false);

    const handleCompletedClose = () => {
        setCompleted(false);
    };

    const handleCompletedShow = () => {
        setCompleted(true);
    };

    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);

    const TestList = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(api_url + "get_test_list", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    if (result.data.find((n) => n.test_id === parsedId)) {
                        const selected_test = result.data.find(
                            (n) => n.test_id === parsedId
                        );
                        setQuizDetails(selected_test);
                    } else {
                        navigate("/dashboard");
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const ResumeTestList = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(api_url + "get_resume_test_list", requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                if (result.data && result.data.length > 0) {
                    if (result.data.find((n) => n.test_id === parsedId)) {
                        const selected_test = result.data.find(
                            (n) => n.test_id === parsedId
                        );
                        setQuizDetails(selected_test);
                    } else {
                        navigate("/dashboard");
                    }
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        if (!token && !authenticate && !id) {
            navigate("/");
        } else {
            setIsLoading(false);
            if (!attempt) {
                TestList();
            } else {
                ResumeTestList();
            }
        }
    }, [token, authenticate, lang]);

    const handleQuiz = (id, credits, title) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("test_id", id);
        formdata.append("credit_id", credits);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "start_test", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === 1) {
                    navigate(
                        `/test/${id}/${result.attempt_id}/${quizDetails["allowed_time(min)"]}/${title}/start/${result.total_questions} `
                    );
                }
            })
            .catch((error) => console.log("error", error));
    };

    const handleResumeQuiz = (id, attempt, title) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept-Language", lang);
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("attempt_id", attempt);
        formdata.append("test_id", id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "resume_test", requestOptions)
            .then((response) => response.json())
            .then((result) => {

                const timeString = result["elapsed_time(h:i:s)"];
                const minutes = timeStringToMinutes(timeString);
                if (result.status === 1) {
                    if(minutes > 0  || minutes === null){
                    navigate(
                        `/test/${id}/${result.attempt_id}/${minutes}/${title}/${resumed} `
                    );}
                    else{
                        handleCompletedShow(true);
                    }
                }
            })

            .catch((error) => console.log("error", error));
    };

    function timeStringToMinutes(timeString) {
        if (timeString === null || timeString === undefined) {
            return null;
        }

        const [hours, minutes, seconds] = timeString.split(":");
        const totalMinutes =
            parseInt(hours) * 60 + parseInt(minutes) + parseInt(seconds) / 60;
        const wholeMinutes = Math.floor(totalMinutes);
        return wholeMinutes;
    }

    if (isLoading) {
        return (
            <div className="text-center">
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{height: "100vh"}}
                >
                    <Spinner
                        animation="border"
                        role="status"
                        style={{width: "5rem", height: "5rem"}}
                    ></Spinner>
                </div>
            </div>
        );
    }

    return (
        <div>
            <NavigationBar/>
            <div className="start-quiz">
                {quizDetails && (
                    <>
                        <h1>{quizDetails.test_title}</h1>

                        <br/>
                        <div className="test-intro">
                            <p>{quizDetails.test_description}</p>
                        </div>
                        <div className="test-time">
                            <h2>
                                <strong>
                                    {t("allowed time")}
                                    {": "}&nbsp;
                                </strong>
                                {quizDetails["allowed_time(min)"]} {t("minutes")}
                            </h2>
                            <h2>
                                <strong>
                                    {t("accepted percentage")}
                                    {": "}
                                </strong>
                                {quizDetails.accepted_percentage}%
                            </h2>
                        </div>
                        <span className="test-heading">{t("instructions")}</span>
                        <div
                            className="test-instructions"
                            style={{whiteSpace: "pre-line"}}
                            dangerouslySetInnerHTML={{__html: quizDetails.test_instruction}}
                        />
                        <Button
                            className="quiz-start-btn"
                            onClick={() => {
                                if (!attempt) {
                                    handleQuiz(
                                        quizDetails.test_id,
                                        quizDetails.test_credits[0].id,
                                        quizDetails.test_title
                                    );
                                } else {
                                    handleResumeQuiz(
                                        quizDetails.test_id,
                                        attempt,
                                        quizDetails.test_title
                                    );
                                }
                            }}
                        >
                            {t("start quiz")}
                        </Button>
                    </>
                )}
            </div>
            <Modal show={completed} onHide={handleCompletedClose} centered>
                <Modal.Title>{t("time up")}</Modal.Title>
                <Modal.Body>
                    <Button onClick={handleCompletedClose}>OK</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default StartQuiz;
