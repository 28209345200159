import NavigationBar from "../Common/Navbar";
import Footer from "../Common/Footer";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Container, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { api_url } from "../Common/Variable";

function Completed() {
	const [result, setResult] = useState("");
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [lang, setLang] = useState(i18n.language);
	const token = localStorage.getItem("token");
	const authenticate = localStorage.getItem("authenticate");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setLang(i18n.language);
	}, [ i18n.language ] );
	
	const TestResults = () => {
		const myHeaders = new Headers();
		myHeaders.append("Accept-Language", lang);
		myHeaders.append("Authorization", `Bearer ${token}`);

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow",
		};

		fetch(api_url + "get_completed_test_list", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				setResult(result.data);
			})
			.catch((error) => console.error(error));
	};

	useEffect(() => {
		if (!token && !authenticate) {
			navigate("/");
		} else {
			setIsLoading(false);
			TestResults();
		}
	}, [token, authenticate, lang]);

	if (isLoading) {
		return (
			<div className="text-center">
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: "100vh" }}
				>
					<Spinner
						animation="border"
						role="status"
						style={{ width: "5rem", height: "5rem" }}
					></Spinner>
				</div>
			</div>
		);
	}

	return (
		<div>
			<NavigationBar hide={false} show={true} />

			<div className="completed">
				<h1>{t("Test Results")}</h1>
				<div className="results">
					{result
						? result.length > 0
							? result.map((n, index) => (
									<div>
										<h2 className="credits">
											{t("credit")} {index + 1}
										</h2>
										<div className="credit-group">
											{n.credit_test_details.map((testDetail, idx) => (
												<div className="result" key={idx}>
													<h2>{testDetail.test_title}</h2>
													<div className="report">
														<strong>{t("ttime")}:</strong>
														<span>
															&nbsp;{testDetail["test_allowed_time(min)"]}
														</span>
														<span>&nbsp;{t("min")}</span>
													</div>
													<div className="report">
														<strong>{t("tq")}:</strong>
														<span>&nbsp;{testDetail.test_total_questions}</span>
													</div>
													<div className="report" style={{ marginTop: "1vw" }}>
														<div className="result-data">
															{Object.entries(
																testDetail.test_marking_details
															).map(([key, value], i) => (
																<span key={i}>
																	{`${key}: ${value}`}
																	<br />
																</span>
															))}
														</div>

														{Object.entries(
															testDetail.test_marking_details
														).map(([key, value], i) => (
															<span key={i}>
																{[3, 4, 5, 6, 7].includes(
																	testDetail.test_id
																) && (
																	<ProgressBar
																		style={{ marginBottom: "0.6vw" }}
																		now={
																			typeof value === "number"
																				? value
																				: value.match(/\d+/) &&
																				  value.match(/\d+/)[0]
																		}
																	/>
																)}
															</span>
														))}
													</div>
												</div>
											))}
											<br />
										</div>
										<br />
									</div>
							  ))
							: ""
						: ""}
				</div>
			</div>
		</div>
	);
}

export default Completed;
